import { defineStore } from 'pinia';
import type { Notification } from '../types/notification';

interface NotificationState {
  notifications: Notification[];
}

export const useNotificationStore = defineStore('notificationStore', {
  state: (): NotificationState => ({
    notifications: [],
  }),
  actions: {
    addNotification(notification: Notification) {
      this.notifications.push(notification);
      // Remover notificação após a duração especificada
      setTimeout(() => {
        this.notifications = this.notifications.filter(n => n.id !== notification.id);
      }, notification.duration || 8000); // Default duration of 8000ms
    },
  },
});
