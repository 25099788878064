<template>
  <transition-group name="slide-fade" tag="div" class="notification-container">
    <div v-for="notification in notifications" :key="notification.id"
      class="fixed bottom-4 left-4 bg-success text-white py-2 px-4 rounded shadow-md">
      {{ notification.message }}
    </div>
  </transition-group>
</template>

<script setup>
import { useNotificationStore } from '~/stores/notificationStore';
import utilsArray from '~~/utils/Utils.Array';

const notificationStore = useNotificationStore();
const notifications = computed(() => {
  return notificationStore.notifications.filter(notification => !utilsArray.isEmptyObject(notification));
});
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notification {
  transition: transform 0.5s, opacity 0.5s;
}

.notification-enter-active,
.notification-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}

.notification-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}

.notification-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.notification-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.notification-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
